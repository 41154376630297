// 引入icon样式文件
// import "../assets/css/iconfont/iconfont"
import partnerSvg from '@/assets/images/partner.svg'
import { MenuObject } from './cms-menus'
import home from '../../src/assets/icon/icons8-home (2).json'
import shipping from '../../src/assets/icon/icons8-shipment.json'
import order from '../../src/assets/icon/icons8-order.json'
import subscription from '../../src/assets/icon/icons8-subscription.json'
import product from '../../src/assets/icon/icons8-product.json'
import consumer from '../../src/assets/icon/icons8-consumer.json'
import marketing from '../../src/assets/icon/icons8-marketing.json'
import ai from '../../src/assets/icon/icons8-ai.json'
import partner from '../../src/assets/icon/icons8-partner.json'
import importandexport from '../../src/assets/icon/icons8-import&export.json'
import invoice from '../../src/assets/icon/icons8-invoice.json'
import channel from '../../src/assets/icon/icons8-channel.json'
import React from 'react'
export const menus: MenuObject[] = [
  {
    name: 'Dashboard',
    value: 'dashboard',
    url: '/dashboard',
    activekey: 'dashboard',
    key: 'salesExperienceDashboard',
    icon: <span className="icon iconfont text-xl icon-a-bianzu33" />,
    lottieIcon: home,
    langKey: 'menu.dashboard',
  },
  {
    name: 'Shipment',
    value: 'shipment',
    url: '',
    key: 'shipment',
    activekey: 'shipment',
    icon: <span className="icon iconfont  icon-a-bianzu10" />,
    lottieIcon: shipping,
    langKey: 'menu.shipment',
    children: [
      {
        name: 'My Shipment',
        value: 'my_shipment',
        url: '/shipment/shipment-list',
        activekey: 'shipment-list',
        key: 'shipment.myShipment',
        langKey: 'menu.my_shipment',
      },
      {
        name: 'Shipping Setting',
        value: 'shipment.shippingSetting',
        url: '/shipment/shipping-setting',
        activekey: 'shipping-setting',
        key: 'shipment.shippingSetting',
        langKey: 'menu.shipping_setting',
      },
    ],
  },
  {
    name: 'Order',
    value: 'order',
    url: '',
    key: 'order',
    activekey: 'order',
    lottieIcon: order,
    icon: <span className="icon iconfont icon-a-bianzu11" />,
    langKey: 'menu.order',
    children: [
      {
        name: 'My Orders',
        value: 'my_orders',
        activekey: 'order-list',
        url: '/order/order-list',
        // url: "/",
        key: 'order.myOrders',
        langKey: 'menu.my_orders',
      },
      // {
      //   name: 'Valet Order',
      //   value: 'valetorder',
      //   activekey: 'valet-order',
      //   url: '/order/valet-order',
      //   // url: "/",
      //   key: 'order.myOrders',
      //   langKey: 'menu.valetorder',
      // },
      {
        name: 'Return Order',
        value: 'return_order',
        activekey: 'return-order',
        url: '/order/order-return-list',
        key: 'order.returnOrder',
        langKey: 'menu.Return Order',

      },
      // 关闭入口，放到order setting里
      // {
      //   name: 'Order flow',
      //   value: 'order_flow',
      //   activekey: 'order-flow',
      //   url: '/order/order-flow',
      //   key: 'order.flow',
      //   langKey: 'menu.order_flow',
      // },
      {
        name: 'Order Setting',
        value: 'order_setting',
        activekey: 'order-setting',
        url: '/order/order-setting',
        key: 'order.orderSetting',
        langKey: 'menu.order_setting',
      }
      // {
      //   name: 'Order Eligible For Return',
      //   value: 'order_eligible_for_return',
      //   activekey: 'order-eligible-for-return',
      //   url: '/order/order-return-apply-list',
      //   key: 'order.orderEligibleForReturn',
      //   langKey: 'menu.Order Eligible For Return',
      // }
      // ,

    ],
  },
  {
    name: 'Subscription',
    value: 'subscription',
    url: '',
    key: 'subscription',
    activekey: 'subscription',
    icon: <span className="icon iconfont icon-a-bianzu11-1" />,
    lottieIcon: subscription,
    langKey: 'menu.subscription',
    children: [
      {
        name: 'My Subscriptions',
        value: 'my_subscriptions',
        activekey: 'subscription-list',
        url: '/subscription/subscription-list',
        key: 'subscription.mySubscriptions',
        langKey: 'menu.my_subscriptions',
      },
      {
        name: 'Subscription Setting',
        value: 'subscription_setting',
        activekey: 'subscription-setting',
        url: '/subscription/subscription-setting',
        key: 'subscription.setting',
        langKey: 'menu.subscriptions_setting',
      },
    ],
  },
  {
    name: 'Product',
    value: 'product',
    url: '',
    key: 'product',
    activekey: 'product',
    icon: <span className="icon iconfont icon-a-bianzu12" />,
    lottieIcon: product,
    langKey: 'menu.product',
    children: [
      {
        name: 'My Products',
        value: 'my_products',
        activekey: 'product-list',
        url: '/product/product-list',
        key: 'product.productList',
        langKey: 'menu.my_products',
      },
      {
        name: 'Add New Product',
        value: 'add_new_product',
        activekey: 'product-add',
        url: '/product/product-list/product-add',
        key: 'product.addNewProduct',
        langKey: 'menu.add_new_product',
      },
      {
        name: 'Search',
        value: 'product_search',
        url: '/product/product-search',
        activekey: 'product-search',
        key: 'product.search',
        langKey: 'menu.search',
      },
      {
        name: 'product.categrey',
        value: 'product_categrey',
        url: '/product/product-categrey',
        activekey: 'product-categrey',
        key: 'product.categrey',
        langKey: 'menu.product.categrey',
      },
      {
        name: 'Descriptions',
        value: 'description',
        activekey: 'description',
        url: '/product/description',
        key: 'product.descriptions',
        langKey: 'menu.description',
      },
      {
        name: 'Attributes',
        value: 'attributes',
        activekey: 'attributes',
        url: '/product/attributes',
        key: 'product.attributes',
        langKey: 'menu.attributes',
      },
      {
        name: 'Filters & Sort',
        value: 'filter',
        url: '/product/filter',
        activekey: 'filter',
        key: 'product.filtersAndSort',
        langKey: 'menu.filter',
      },
      {
        name: 'Product Setting',
        value: 'product-setting',
        url: '/product/product-setting',
        activekey: 'productSetting',
        key: 'product.productSetting',
        langKey: 'menu.productSetting',
      },
    ],
  },
  {
    name: 'Pet Owner',
    value: 'petOwner',
    url: '',
    key: 'consumer',
    activekey: 'consumer',
    icon: <span className="icon iconfont icon-a-bianzu12-1" />,
    lottieIcon: consumer,
    langKey: 'menu.pet_owner',
    children: [
      {
        name: 'My Pet Owner',
        value: 'my_pet_owner',
        url: '/consumer/consumer-list',
        activekey: 'consumer-list',
        key: 'consumer.consumerList',
        langKey: 'menu.my_pet_owner',
      },
      {
        name: 'Tagging Setting',
        value: 'tagging_setting',
        activekey: 'tag-list',
        url: '/tags/tag-list',
        key: 'consumer.taggingSetting',
        langKey: 'menu.tagging_setting',
      },
      {
        name: 'Loyalty',
        value: 'loyalty',
        url: '',
        key: 'consumer.loyalty',
        activekey: 'loyalty',
        langKey: 'menu.loyalty',
        children: [
          {
            name: 'Loyalty Program',
            value: 'loyalty_program',
            activekey: 'loyalty-programs',
            url: '/consumer/loyalty/loyalty-programs',
            key: '',
            langKey: 'menu.loyalty_program',
          },
          {
            name: 'Points',
            value: 'points',
            url: '/consumer/loyalty/points',
            activekey: 'points',
            key: '',
            langKey: 'menu.points',
          },
          {
            name: 'Referrals',
            value: 'referrals',
            url: '/consumer/loyalty/referrals',
            activekey: 'referrals',
            key: '',
            langKey: 'menu.referrals',
          },
          {
            name: 'Reward History',
            value: 'rewardHistory',
            activekey: 'reward-history',
            url: '/consumer/loyalty/reward-history',
            key: '',
            langKey: 'menu.rewardHistory',
          },
          {
            name: 'Reward Settings',
            value: 'rewardSettings',
            url: '/consumer/loyalty/reward-settings-list',
            activekey: 'reward-settings-list',
            langKey: 'menu.rewardSettings',
            key: ''
          }]
      },

    ],
  },
  {
    name: 'Marketing Automation',
    value: 'marketing_centres',
    url: '',
    key: 'marketing',
    activekey: 'marketingCenter',
    icon: <span className="icon iconfont icon-a-bianzu13" />,
    lottieIcon: marketing,
    langKey: 'menu.marketing',
    children: [
      {
        name: 'Marketing Experience',
        value: 'MarketingCenters',
        url: '/marketingCenter/marketingCenter-list',
        activekey: 'marketingCenter-list',
        key: 'marketing.marketingCenter',
        langKey: 'menu.marketing_center',
      },
      {
        name: 'Intelligent Recommendation',
        value: 'IntelligentRecommendation',
        activekey: 'intelligentRecommendation-list',
        url: '/marketingCenter/intelligentRecommendation-list',
        key: 'marketing.intelligentRecommendation',
        langKey: 'menu.intelligent_recommendation',
      },
    ],
  },
  {
    name: 'AIReco',
    value: 'aireco',
    url: '',
    key: 'aIReco',
    activekey: 'aireco-model',
    icon: <span className="icon iconfont text-xl icon-aireco" />,
    lottieIcon: ai,
    langKey: 'menu.aireco',
    children: [
      {
        name: 'Al Reco Models',
        value: 'ai_reco_models',
        url: '/aireco-model/list',
        activekey: 'list',
        key: 'aIReco.alRecoModels',
        langKey: 'menu.aireco_models',
      },
      {
        name: 'Recommendation History',
        value: 'recommendation_history',
        activekey: 'recommendation-history',
        url: '/aireco-model/recommendation-history',
        key: 'aIReco.recommendationHistory',
        langKey: 'menu.recommendation_history',
      },
    ],
  },
  {
    name: 'Finance',
    value: 'finance',
    url: '',
    key: 'finance',
    activekey: 'payment',
    lottieIcon: invoice,
    icon: <span className="icon iconfont icon-a-bianzu14" />,
    langKey: 'menu.finance',
    children: [
      {
        name: 'Payment Settings',
        value: 'payment_settings',
        url: '/payment/setting-list',
        activekey: 'setting-list',
        key: 'finance.paymentSettings',
        langKey: 'menu.payment_settings',
      },
      {
        name: 'Invoice List',
        value: 'invoice_list',
        activekey: 'invoice-list',
        url: '/payment/invoice-list',
        key: 'finance.invoiceList',
        langKey: 'menu.invoice_list',
      },
    ],
  },
  // {
  //   name: 'Shop',
  //   value: 'shop',
  //   url: '',
  //   key: 'shop',
  //   icon: <span className="icon iconfont icon-a-bianzu18" />,
  //   langKey: 'menu.shop',
  //   children: [
  //     {
  //       name: 'Shop Rating',
  //       value: 'shop_rating',
  //       url: '/category/shop-rating',
  //       key: 'shop_rating',
  //     },
  //     {
  //       name: 'Shop Category',
  //       value: 'shop_category',
  //       url: '/shop/shop-category',
  //       key: 'shop_category',
  //       langKey: 'menu.shop_category',
  //     },
  //     {
  //         name: 'Customer Service',
  //         value: 'customer_service',
  //         url: '/shop/customer-service',
  //         key: 'customer_service',
  //         langKey: 'menu.customer_service',
  //       }, {
  //       name: 'Site Map',
  //       value: 'site_map',
  //       url: '/shop/site-map',
  //       key: 'site_map',
  //       langKey: 'menu.site_map',
  //     },
  //   ],
  // },
  {
    name: 'Channel Management',
    value: 'wechat_management',
    url: '',
    key: 'salesChannel',
    activekey: 'shop',
    lottieIcon: channel,
    icon: <span className='iconfont icon icon-channel3 text-[17px]'></span>,
    // icon: <span className="icon iconfont icon-channel" />,
    langKey: 'menu.social_media',
    children: [{
      key: "salesChannel.onlineStore",
      name: 'Online Store',
      url: '',
      langKey: 'menu.online_store',
      activekey: 'onlineStore',
      value: 'Online Store',
      children: [
        {
          name: 'Shop Category',
          value: 'shop_category',
          url: '/shop/shop-category',
          activekey: 'shop-category',
          key: 'salesChannel.onlineStore.shopCategory',
          langKey: 'menu.shop_category',
        }, {
          name: 'Customer Service',
          value: 'customer_service',
          url: '/shop/customer-service',
          activekey: 'customer-service',
          key: 'salesChannel.onlineStore.customerService',
          langKey: 'menu.customer_service',
        }, {
          name: 'Site Map',
          value: 'site_map',
          url: '/shop/site-map',
          activekey: 'site-map',
          key: 'salesChannel.onlineStore.sitemap',
          langKey: 'menu.site_map',
        }, {
          name: 'Preferences',
          value: 'preferences',
          url: '/shop/preferences',
          activekey: 'preferences',
          key: 'salesChannel.onlineStore.preferences',
          langKey: 'menu.preferences',
        }
      ]
    },
    {
      name: 'Points of Sales',
      value: 'points_of_sales',
      url: '/pos',
      key: 'salesChannel.pointsofSales',
      activekey: 'pos',
      langKey: 'menu.points_of_sales',
    },
    {
      name: 'WeChat Mini Program',
      // icon: <span className="icon iconfont icon-channel" />,
      url: '',
      key: 'salesChannel.weChatMiniprogram',
      langKey: 'menu.wechat_mini_program',
      activekey: 'channel',
      value: 'channel',
      children: [
        {
          name: 'Account Management',
          value: 'account_management',
          url: '/channel/account/account-list',
          activekey: 'account/account-list',
          key: 'salesChannel.weChatMiniprogram.accountManagement',
          langKey: 'menu.account_management',
        },
        {
          name: 'Fans Management',
          value: 'fans_management',
          url: '/channel/fans/fans-list',
          activekey: 'fans/fans-list',
          key: 'salesChannel.weChatMiniprogram.fansManagement',
          langKey: 'menu.fans_management',
        },
        {
          name: 'Automatic Replies',
          value: 'automatic_replies',
          url: '/channel/auto-reply',
          activekey: 'auto-reply/auto-reply-list',
          key: 'salesChannel.weChatMiniprogram.automaticReplies',
          langKey: 'menu.automatic_replies',
        },
        {
          name: 'Reply Content',
          value: 'response_content',
          url: '/channel/reply',
          activekey: 'reply/reply-contents',
          key: 'salesChannel.weChatMiniprogram.replyContent',
          langKey: 'menu.reply_content',
        },
        {
          name: 'Assets Management',
          value: 'assets_management',
          url: '/channel/assets/assets-management',
          activekey: 'assets/assets-management',
          key: 'salesChannel.weChatMiniprogram.assetsManagement',
          langKey: 'menu.assets_management',
        },
        {
          name: 'Menu Management',
          value: 'menu_management',
          url: '/channel/menuManagempqr',
          activekey: 'menuManagempqr/menu-manage-list',
          key: 'salesChannel.weChatMiniprogram.menuManagement',
          langKey: 'menu.menu_management',
        },
        {
          name: 'QR Code Management',
          value: 'qr_code_management',
          activekey: 'QrcodeManage',
          url: '/channel/QrcodeManage',
          key: 'salesChannel.weChatMiniprogram.qRCodeManagement',
          langKey: 'menu.qr_code_management',
        },
        {
          name: 'Template Message',
          value: 'template_message',
          url: '/channel/template',
          activekey: 'template/template-message-list',
          key: 'salesChannel.weChatMiniprogram.templateMessage',
          langKey: 'menu.template_message',
        },
        {
          name: 'Mini Program QR Code',
          value: 'mini_program_qr_code',
          url: '/channel/mpqr',
          activekey: 'mpqr/mpqr-list',
          key: 'salesChannel.weChatMiniprogram.miniProgramQRCode',
          langKey: 'menu.mini_program_qr_code',
        },
        {
          name: 'Mini Program Banner',
          value: 'mini_program_banner',
          activekey: 'mpbanner/mpbanner-list',
          url: '/channel/mpbanner',
          key: 'salesChannel.weChatMiniprogram.miniProgramBanner',
          langKey: 'menu.mini_program_banner',
        },
      ],
    }, {
      name: 'Channel Options',
      url: '/channel-options',
      key: 'salesChannel.channelOptions',
      activekey: 'channel-options',
      langKey: 'menu.channel_options',
      value: ''
    }],

  },
  {
    name: 'Partner',
    value: 'partner',
    url: '',
    key: 'partner',
    icon: <span className='iconfont icon icon-_partner  text-[17px]'></span>,
    // icon: <img src={partnerSvg} alt="" width={18} style={{ margin: '0px 2px 0 1px' }} />,
    langKey: 'menu.partner',
    lottieIcon: partner,
    activekey: 'partner',
    children: [
      {
        name: 'Partner Type',
        value: 'partner_type',
        url: '/partner/partner-type',
        key: 'partner.partnerType',
        langKey: 'menu.partner_type',
        activekey: 'partner-type',
      },
      {
        name: 'Partner List',
        value: 'partner_list',
        url: '/partner/partner-list',
        activekey: 'partner-list',
        key: 'partner.PartnerList',
        langKey: 'menu.partner_list'
      }
    ]
  },
  {
    name: 'Exports',
    url: '/export-report',
    key: 'salesChannel.exportReport',
    icon: <span className='iconfont icon icon-tiaobojilu  text-[17px]'></span>,
    activekey: 'export-report',
    langKey: 'menu.exports',
    value: 'export-report',
    lottieIcon: importandexport,
  },
]

// export const initActive = (pathname: string) => {
//   let selectedKeys: string[] = []
//   menus.forEach((menu) => {
//     menu.children.forEach((subMenu) => {
//       if (subMenu.url === pathname) {
//         selectedKeys = [menu.key, subMenu.key]
//       }
//     })
//   })
//   return selectedKeys
// }
