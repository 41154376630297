import React from 'react'
import { lazy } from 'react' // 路由懒加载
import { RouteObject } from './index'
const Layout = lazy(() => import('@/common/components/Layout'))
// const Layout = lazy(() => import('../components/common/SettingLayout'))
const ReportEmbed = lazy(() => import('@/views/reportEmbed'))
const Login = lazy(() => import('../views/login'))
const LoginStore = lazy(() => import('../views/loginStore'))
const LoginBrand = lazy(() => import('../views/loginBrand'))
const SelectStore = lazy(() => import('@/common/components/selectStore'))
const ResetPassword = lazy(() => import('../views/resetPassword'))
const Register = lazy(() => import('../views/register'))
const AuthLoading = lazy(() => import('@/views/authLoading'))
let routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: 'reportEmbed',
        breadcrumbName: 'reportEmbed',
        element: <ReportEmbed />,
        key: 'report_embed'
      },
    ],
  },
  { path: '/login', element: <Login />, key: 'store' },
  { path: '/authloading', element: <AuthLoading />, key: 'store' },
  { path: '/store/select', element: <SelectStore />, key: 'store' },
  { path: '/login/store', element: <LoginStore />, key: 'store' },
  { path: '/login/brand', element: <LoginBrand />, key: 'store' },
  { path: '/resetPassword', element: <ResetPassword />, key: 'store' },
  { path: '/register', element: <Register />, key: 'store' },
  { path: '*', element: <Login />, key: 'store' },
]

export default routes
