import React from 'react'
import Menus from '@/common/components/Menus'
import { menus } from "@/lib/menus";
import { SettingMenus } from "@/lib/setting-menus";
import { useContext } from 'react'
import { TabContext } from "@/contexts/TabContext";

export const getMenuData = (activeTab: number) => {
  let menuData: any = menus
  switch (activeTab) {
    case 2:
      // @ts-ignore
      menuData = SettingMenus
      break
  }
  return menuData
}
export default function MenusWrapper() {
  const { activeTab } = useContext(TabContext)

  return (
    <Menus menus={activeTab === 2 ? SettingMenus : menus} />
  )
}
