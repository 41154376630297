/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-01-17 11:05:26
 * @LastEditors: yuszhou yuszhou@deloitte.com.cn
 * @LastEditTime: 2023-03-14 14:47:42
 * @FilePath: \rc_china_storeportal\src\routers\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import React from 'react'
import { lazy } from 'react' // 路由懒加载
// import ProductList from '../views/productList'
const Layout = lazy(() => import('@/common/components/Layout'))
const Home = lazy(() => import('../views/index'))
const ReportEmbed = lazy(() => import('@/views/reportEmbed'))
const Login = lazy(() => import('../views/login'))
const LoginStore = lazy(() => import('../views/loginStore'))
const SelectStore = lazy(() => import('@/common/components/selectStore'))
const LoginBrand = lazy(() => import('../views/loginBrand'))
const ProductList = lazy(() => import('../views/productList'))
const AddProduct = lazy(() => import('../views/productDetail'))
const ProductSearch = lazy(() => import('../views/productSearch'))
const SynonymsDetail = lazy(() => import('../views/productSearch/SynonymsDetail'))
const ResetPassword = lazy(() => import('../views/resetPassword'))
const Register = lazy(() => import('../views/register'))
const PetOwnerList = lazy(() => import('@/views/petOwnerList'))
const PetOwnerDetail = lazy(() => import('@/views/petOwnerDetail'))
const PetDetail = lazy(() => import('@/views/petDetail'))
const PetPoints = lazy(() => import('@/views/petPoints'))
const PetReferrals = lazy(() => import('@/views/petReferrals'))
const OrderList = lazy(() => import('@/views/orderList'))
const ValetOrder = lazy(() => import('@/views/valetOrder'))
const OrderDetail = lazy(() => import('@/views/orderDetail'))
const Payment = lazy(() => import('@/views/paymentSetting'))
const CategoryList = lazy(() => import('@/views/categoryList'))
const CategoryDetail = lazy(() => import('@/views/categoryDetail'))
const CategoryManualDetail = lazy(() => import('@/views/categoryManualDetail'))
const CategoryListSort = lazy(() => import('@/views/categoryListSort'))
const ShippingSetting = lazy(() => import('@/views/shippingSetting'))
const OrderSetting = lazy(() => import('@/views/orderSetting'))
const BlockList = lazy(() => import('@/views/orderSetting/blockList'))
const OrderEligibleForReturnList = lazy(() => import('@/views/orderEligibleForReturn/list'))
const OrderEligibleForReturnAdd = lazy(() => import('@/views/orderEligibleForReturn/add'))
const ReturnOrderList = lazy(() => import('@/views/returnOrder/list'))
const ReturnOrderDetail = lazy(() => import('@/views/returnOrder/detail'))

const AccountList = lazy(() => import('@/views/accountList'))
const AddAccount = lazy(() => import('@/views/accountCreate'))
const FansList = lazy(() => import('@/views/fansList'))
const FansDetail = lazy(() => import('@/views/fansDetail'))
const InvoiceList = lazy(() => import('@/views/InvoiceList'))
const AutoReplyList = lazy(() => import('@/views/autoReplyList'))
const AddAutoReply = lazy(() => import('@/views/addAutoReply'))
const ReplyContents = lazy(() => import('@/views/replyContents'))
const AddReplyContent = lazy(() => import('@/views/replyContentCreate'))
const AssetList = lazy(() => import('@/views/assetList'))
const MpBannerList = lazy(() => import('@/views/mpBannerList'))
const MpQRList = lazy(() => import('@/views/mpQRList'))
const QrCodeManage = lazy(() => import('@/views/qrCodeManageList'))
const MenuManage = lazy(() => import('@/views/menuManageList'))
const WxTemplateMessage = lazy(() => import('@/views/templateMessageList'))
const MpBannerDetail = lazy(() => import('@/views/mpBannerDetail'))
const MpBannerAdd = lazy(() => import('@/views/mpBannerAdd'))
const MpQRDetail = lazy(() => import('@/views/mpQRDetail'))
const QrCodeManageDetail = lazy(() => import('@/views/qrCodeManageDetail'))
const MenuManageDetail = lazy(() => import('@/views/menuManageDetail'))
const TemplateMessageDetail = lazy(() => import('@/views/templateMessageDetail'))
const TagList = lazy(() => import('@/views/tagList'))
const EditTags = lazy(() => import('@/views/tagEdit'))
const AddVideo = lazy(() => import('@/views/addVideo'))
const MarketingCentreList = lazy(() => import('@/views/marketingCenter'))
const Vouchers = lazy(() => import('@/views/voucherList'))
const CreateNewVoucher = lazy(() => import('@/views/voucherCreate'))
const SubscriptionList = lazy(() => import('@/views/subscriptionList'))
const SubscriptionSetting = lazy(() => import('@/views/subscriptionSetting'))
const SubscriptionDetail = lazy(() => import('@/views/subscriptionDetail'))
const OrderswithVoucher = lazy(() => import('@/views/voucherOrders'))
const ComingSoon = lazy(() => import('@/views/comingSoon'))
const LiveStreamingList = lazy(() => import('@/views/liveStreamingList'))
const IntelligentRecommendation = lazy(() => import('@/views/intelligentRecommendation'))
const AddGraphic = lazy(() => import('@/views/addGraphic'))
const Dashboard = lazy(() => import('@/views/dashboard'))
const CustomerService = lazy(() => import('@/views/customerService'))
const SiteMap = lazy(() => import('@/views/siteMap'))
const Preferences = lazy(() => import('@/views/preferences'))
const AiRecoModelList = lazy(() => import('@/views/aiRecoModelList'))
const RecommendHistory = lazy(() => import('@/views/recommendHistory'))
const AiRecoModelDetail = lazy(() => import('@/views/aiRecoModelDetail'))
const AiRecoModelEdit = lazy(() => import('@/views/aiRecoModelEdit'))
const DescriptionList = lazy(() => import('@/views/descriptionList'))
const DescriptionDetail = lazy(() => import('@/views/descriptionDetail'))
const AttributesList = lazy(() => import('@/views/attributesList'))
const AddAttributes = lazy(() => import('@/views/addAttributes'))
const AttributesInfo = lazy(() => import('@/views/attributesInfo'))
const ShopCategory = lazy(() => import('@/views/shopCategory'))
const CategoryInfo = lazy(() => import('@/views/categoryInfo'))
const FilterManagement = lazy(() => import('@/views/filter'))
const PartnerType = lazy(() => import('@/views/partnerType'))
const PartnerList = lazy(() => import('@/views/partnerList'))
const PartnerDetail = lazy(() => import('@/views/partnerDetail'))
const LoyalProgram = lazy(() => import('@/views/loyalProgram'))
const RewardSettingsList = lazy(() => import('@/views/rewardSettingsList'))
const RewardSettingDetail = lazy(() => import('@/views/rewardSettingsList/components/Detail'))
const RewardHistory = lazy(() => import('@/views/rewardHistory'))
const PromotionsHome = lazy(() => import('@/views/promotion/home'))
const CreateOredit = lazy(() => import('@/views/promotion/createOredit'))
const ChannelOptions = lazy(() => import('@/views/channelOptions'))
const MappingOrder = lazy(() => import('@/views/promotion/mappingOrder'))
const PosIndex = lazy(() => import('@/views/pos/index'))
const PosCheckoutIndex = lazy(() => import('@/views/pos/checkout'))
const TierSetting = lazy(() => import('@/views/tierSetting'))
const AuthLoading = lazy(() => import('@/views/authLoading'))
const BatchExport = lazy(() => import('@/views/batchExport'))
const ExportReport = lazy(() => import('@/views/batchExportReport'))
const ProductCategret = lazy(() => import('@/views/productCategrey'))

const OrderFlowReturn = lazy(() => import('@/views/orderFlow/orderFlow'))
const OrderFlowList = lazy(() => import('@/views/orderFlow/orderFlowlist'))
const OrderFlowSales = lazy(() => import('@/views/orderFlow/orderflowSales'))

const ProductSetting = lazy(() => import('@/views/productSetting'))

const UploadReport = lazy(() => import('@/views/uploadReport'))

export interface RouteObject {
  caseSensitive?: boolean
  children?: RouteObject[]
  element?: React.ReactNode
  index?: boolean
  path?: string
  breadcrumbName?: string
  Navigate?: string
  key?: string
}

let routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: 'home', element: <Home /> },
      { path: 'dashboard', element: <Dashboard />, key: 'sale_dashboard' },
      {
        path: 'channel-options',
        breadcrumbName: 'Channel Options',
        element: <ChannelOptions />,
        key: 'channel_options',
      },
      { path: 'pos', element: <PosIndex />, key: 'points_of_sales', breadcrumbName: 'Points of Sales' },
      { path: 'batch-export/:from', element: <BatchExport />, key: 'batch_export', breadcrumbName: 'Batch Export' },
      { path: 'export-report', element: <ExportReport />, key: 'export_report', breadcrumbName: 'menu.exports' },
      {
        path: 'aireco-model',
        breadcrumbName: 'AlReco',
        children: [
          {
            index: true,
            element: <AiRecoModelList />,
          },
          {
            path: 'list',
            breadcrumbName: 'Al Reco Models',
            element: <AiRecoModelList />,
          },
          {
            path: 'detail',
            breadcrumbName: 'Model Detail',
            element: <AiRecoModelDetail />,
          },
          {
            path: 'edit',
            breadcrumbName: 'Edit Model',
            element: <AiRecoModelEdit />,
          },
          { path: 'recommendation-history', element: <RecommendHistory />, breadcrumbName: 'Recommendation History' },
        ],
      },
      // { index: true , element:<Routes><Route  element={<Navigate to={'/Home'} />} /></Routes> },

      {
        path: 'shipment',
        breadcrumbName: 'Shipment',
        children: [
          { index: true, element: <OrderList /> },
          { path: 'shipment-list', element: <OrderList />, breadcrumbName: 'My Shipment' },
          { path: 'shipping-setting', element: <ShippingSetting />, breadcrumbName: 'Shipping Setting' },
        ],
      },

      {
        path: 'tags',
        breadcrumbName: 'Consumer',
        children: [
          { index: true, element: <TagList /> },
          {
            path: 'tag-list', breadcrumbName: 'Tagging Setting',
            children: [
              { index: true, element: <TagList /> },
              { path: 'edit-tags', element: <EditTags />, breadcrumbName: 'Tagging Details' },

            ]
          },
        ],
      },

      {
        path: 'product',
        breadcrumbName: 'Product',
        children: [
          { index: true, element: <ProductList /> },
          {
            path: 'product-list',
            breadcrumbName: 'Product List',
            children: [
              { index: true, element: <ProductList /> },
              { path: 'product-detail', element: <AddProduct />, breadcrumbName: 'Product Detail' },
              { path: 'product-add', element: <AddProduct />, breadcrumbName: 'Add Product' },
            ],
          },
          {
            path: 'product-search',
            breadcrumbName: 'Product Search',
            children: [
              { index: true, element: <ProductSearch /> },
              { path: 'add-synonyms', element: <SynonymsDetail />, breadcrumbName: 'search.Add synonyms' },
              { path: 'edit-synonyms', element: <SynonymsDetail />, breadcrumbName: 'search.Edit synonyms' },
            ],
          },
          {
            path: 'product-categrey',
            breadcrumbName: 'Product Categrey',
            children: [{ index: true, element: <ProductCategret /> }],
          },
          {
            path: 'description',
            breadcrumbName: 'Descriptions',
            children: [
              { index: true, element: <DescriptionList /> },
              { path: 'description-add', element: <DescriptionDetail />, breadcrumbName: 'Add Description' },
            ],
          },
          {
            path: 'attributes',
            breadcrumbName: 'Attributes',
            children: [
              {
                index: true,
                element: <AttributesList />,
              },
              {
                path: 'add',
                element: <AddAttributes />,
                breadcrumbName: 'Add Attributes',
              },
              {
                path: 'base-info',
                element: <AttributesInfo />,
                breadcrumbName: 'Attributes Basic Info',
              },
            ],
          },
          { path: 'filter', element: <FilterManagement />, breadcrumbName: 'Filters & Sort' },
          { path: 'product-setting', element: <ProductSetting />, breadcrumbName: 'menu.productSetting' },
        ],
      },
      {
        path: 'consumer',
        breadcrumbName: 'Consumer',
        children: [
          { index: true, element: <PetOwnerList /> },
          {
            path: 'consumer-list', breadcrumbName: 'menu.my_pet_owner'
            , children: [
              {
                index: true,
                element: <PetOwnerList />,
              },
              { path: 'consumer-detail', element: <PetOwnerDetail />, breadcrumbName: 'Consumer Detail' },
            ]
          },
          { path: 'pet-detail', element: <PetDetail />, breadcrumbName: 'Pet Detail' },
          {
            path: 'loyalty',
            breadcrumbName: 'Loyalty Programs',
            children: [
              { index: true, element: <LoyalProgram /> },
              { path: 'points', element: <PetPoints />, breadcrumbName: 'Points' },
              { path: 'referrals', element: <PetReferrals />, breadcrumbName: 'Referrals' },
              {
                path: 'loyalty-programs', breadcrumbName: 'Loyalty Programs',
                children: [
                  { index: true, element: <LoyalProgram /> },
                  { path: 'tier-setting', element: <TierSetting />, breadcrumbName: 'Tier Setting' },
                ]
              },
              {
                path: 'reward-settings-list',
                breadcrumbName: 'Reward Settings',
                children: [
                  { index: true, element: <RewardSettingsList /> },
                  { path: 'reward-add', element: <RewardSettingDetail />, breadcrumbName: 'Add Reward' },
                  { path: 'reward-detail', element: <RewardSettingDetail />, breadcrumbName: 'Reward Detail' },
                ],
              },
              { path: 'reward-history', element: <RewardHistory />, breadcrumbName: 'Reward History' },
            ],
          },
        ],
      },
      {
        path: 'payment',
        breadcrumbName: 'Finance',
        children: [
          { index: true, element: <Payment /> },
          { path: 'setting-list', element: <Payment />, breadcrumbName: 'Payment Settings' },
          {
            path: 'invoice-list',
            breadcrumbName: 'Invoice List',
            element: <InvoiceList />,
          },
        ],
      },

      {
        path: 'category',
        breadcrumbName: 'Category',
        children: [
          { index: true, element: <CategoryList /> },
          { path: 'category-list', element: <CategoryList /> },
          { path: 'category-detail', element: <CategoryDetail />, breadcrumbName: 'Category Detail' },
          { path: 'category-manual-detail', element: <CategoryManualDetail />, breadcrumbName: 'Category Detail' },
          { path: 'category-list-sort', element: <CategoryListSort />, breadcrumbName: 'Category Detail' },
        ],
      },
      {
        path: 'shop',
        breadcrumbName: 'Shop',
        children: [
          { index: true, element: <ShopCategory /> },
          {
            path: 'shop-category',
            breadcrumbName: 'Shop Category',
            children: [
              { index: true, element: <ShopCategory /> },
              { path: 'category-info', element: <CategoryInfo />, breadcrumbName: 'Category Detail' },
            ],
          },
          { path: 'customer-service', element: <CustomerService />, breadcrumbName: 'Customer Service' },
          { path: 'site-map', element: <SiteMap />, breadcrumbName: 'Site Map' },
          { path: 'preferences', element: <Preferences />, breadcrumbName: 'menu.preferences' },
        ],
      },

      {
        path: 'order',
        breadcrumbName: 'Order',
        children: [
          { index: true, element: <OrderList /> },
          {
            path: 'order-list',
            breadcrumbName: 'My Orders',
            children: [
              { index: true, element: <OrderList /> },
              { path: 'order-detail', element: <OrderDetail />, breadcrumbName: 'Order Detail' },
            ],
          }, {
            path: 'valet-order',
            breadcrumbName: 'Valet Order',
            children: [
              { index: true, element: <ValetOrder /> },
              // { path: 'order-detail', element: <OrderDetail />, breadcrumbName: 'Order Detail' },
            ],
          },
          {
            path: 'order-setting',
            breadcrumbName: 'Order Setting',
            children: [
              { index: true, element: <OrderSetting /> },
              { path: 'blocklist', element: <BlockList />, breadcrumbName: 'blockList' },
              {
                path: 'order-flow',
                breadcrumbName: 'menu.order_flow',
                children: [
                  { index: true, element: <OrderFlowList /> },
                  {
                    path: 'sales-order',
                    children: [{ index: true, element: <OrderFlowSales /> }],
                    breadcrumbName: 'sales-order',
                  },
                  {
                    path: 'return-order',
                    children: [{ element: <OrderFlowReturn />, index: true }],
                    breadcrumbName: 'return-order',
                  },
                ],
              },
            ],
          },
          {
            path: 'order-return-list',
            breadcrumbName: 'menu.Return Order',
            children: [
              { index: true, element: <ReturnOrderList /> },
              { path: 'order-return-detail', element: <ReturnOrderDetail />, breadcrumbName: 'Detail' },
              {
                path: 'apply',
                breadcrumbName: 'order.Return Order Application',
                children: [
                  { index: true, element: <OrderEligibleForReturnList /> },
                  { path: 'add-order', element: <OrderEligibleForReturnAdd />, breadcrumbName: 'Add' },
                ],
              },
            ],
          },
        ],
      },

      {
        path: 'marketingCenter',
        breadcrumbName: 'Marketing Experience',
        children: [
          { index: true, element: <MarketingCentreList /> },
          {
            path: 'marketingCenter-list', breadcrumbName: 'Marketing Center',
            children: [
              { index: true, element: <MarketingCentreList /> },
              {
                path: 'vouchers',
                breadcrumbName: 'Vouchers',
                children: [
                  { index: true, element: <Vouchers /> },
                  { path: 'createNewVoucher', element: <CreateNewVoucher />, breadcrumbName: 'Create New Voucher' },
                  { path: 'voucherDetails', element: <CreateNewVoucher />, breadcrumbName: 'voucher Details' },
                  { path: 'orderswithVoucher', element: <OrderswithVoucher />, breadcrumbName: 'Orders with Voucher' },
                ],
              },
              {
                path: 'promotions',
                breadcrumbName: 'Discount Promotions',
                children: [
                  { index: true, element: <PromotionsHome />, breadcrumbName: 'Create New Promotion' },
                  { path: 'createNewPromotion', element: <CreateOredit />, breadcrumbName: 'Create New Promotion' },
                  { path: 'promotionDetail', element: <CreateOredit detail={true} />, breadcrumbName: 'Promotion Detail' },
                  { path: 'orderwithpromotion', element: <MappingOrder />, breadcrumbName: 'Order with Promotion' },
                ],
              },

            ]
          },
          {
            path: 'liveStreaming',
            breadcrumbName: 'Live Streaming',
            children: [{ index: true, element: <LiveStreamingList /> }],
          },
          {
            path: 'comingSoon',
            breadcrumbName: 'Coming soon',
            element: <ComingSoon />,
          },
          {
            path: 'intelligentRecommendation-list',
            element: <IntelligentRecommendation />,
            breadcrumbName: 'Intelligent Recommendation',
          },
        ],
      },
      {
        path: 'subscription',
        breadcrumbName: 'Subscriptions',
        children: [
          { index: true, element: <SubscriptionList /> },
          {
            path: 'subscription-list',
            // element: <SubscriptionList />, 
            breadcrumbName: 'My Subscriptions',
            children: [
              { index: true, element: <SubscriptionList /> },
              { path: 'subscription-detail', element: <SubscriptionDetail />, breadcrumbName: 'Subscription Detail' },
            ]
          },
          { path: 'subscription-setting', element: <SubscriptionSetting />, breadcrumbName: 'Subscription Setting' },
        ],
      },
      {
        path: 'channel',
        breadcrumbName: 'Channel',
        children: [
          { index: true, element: <AccountList /> },
          {
            path: 'account',
            breadcrumbName: 'Account Management',
            children: [
              { index: true, element: <AccountList /> },
              {
                path: 'account-list',
                children: [
                  { index: true, element: <AccountList /> },
                  { path: 'add-account', element: <AddAccount />, breadcrumbName: 'Add Account' },
                  { path: 'account-details', element: <AddAccount />, breadcrumbName: 'Edit Account' },

                ]
              },
            ],
          },
          {
            path: 'fans',
            breadcrumbName: 'Fans Management',
            children: [
              { index: true, element: <FansList /> },
              { path: 'fans-list', element: <FansList /> },
              { path: 'fans-detail', element: <FansDetail />, breadcrumbName: 'Fans Detail' },
            ],
          },
          {
            path: 'auto-reply',
            breadcrumbName: 'Automatic Replies',
            children: [
              { index: true, element: <AutoReplyList /> },
              { path: 'add-auto-reply', element: <AddAutoReply />, breadcrumbName: 'Add New Automatic Reply' },
              { path: 'edit-auto-reply', element: <AddAutoReply />, breadcrumbName: 'Edit Automatic Reply' },
            ],
          },
          {
            path: 'reply',
            breadcrumbName: 'Reply Content',
            children: [
              { index: true, element: <ReplyContents /> },
              { path: 'add-reply-content', element: <AddReplyContent />, breadcrumbName: 'Add New Reply Content' },
              { path: 'edit-reply-content', element: <AddReplyContent />, breadcrumbName: 'Edit Reply Content' },
            ],
          },
          {
            path: 'assets',
            breadcrumbName: 'Assets Management',
            children: [
              { index: true, element: <AssetList /> },
              { path: 'assets-management', element: <AssetList /> },
              { path: 'add-video', element: <AddVideo />, breadcrumbName: 'Add Video' },
              { path: 'add-graphic', element: <AddGraphic />, breadcrumbName: 'Add Graphic message' },
            ],
          },
          {
            path: 'menuManagempqr',
            breadcrumbName: 'Menu Management',
            children: [
              { index: true, element: <MenuManage /> },
              {
                path: 'menu-manage-detail/:id',
                element: <MenuManageDetail pageType="edit" />,
                breadcrumbName: 'MenuManage Detail',
              },
              {
                path: 'menu-manage-add',
                element: <MenuManageDetail pageType="add" />,
                breadcrumbName: 'MenuManage Add',
              },
            ],
          },
          {
            path: 'QrcodeManage',
            breadcrumbName: 'QR Code Management',
            children: [
              { index: true, element: <QrCodeManage /> },
              { path: 'qrcode-manage-add', element: <QrCodeManageDetail />, breadcrumbName: 'Add New QR Code' },
            ],
          },
          {
            path: 'template',
            breadcrumbName: 'Template Message',
            children: [
              {
                index: true,
                element: <WxTemplateMessage />,
              },
              {
                path: 'template-message/:id',
                element: <TemplateMessageDetail />,
                breadcrumbName: 'Template Message Detail',
              },
            ],
          },
          {
            path: 'mpqr',
            breadcrumbName: 'Mini Program QR Code',
            children: [
              { index: true, element: <MpQRList /> },
              { path: 'mpqr-detail', element: <MpQRDetail />, breadcrumbName: 'MP QR Code Detail' },
              { path: 'mpqr-add', element: <MpQRDetail />, breadcrumbName: 'Add MP QR Code' },
            ],
          },
          {
            path: 'mpbanner',
            breadcrumbName: 'Mini Program Banner',
            children: [
              { index: true, element: <MpBannerList /> },
              { path: 'mpbanner-detail', element: <MpBannerDetail />, breadcrumbName: 'MP Banner Detail' },
              { path: 'mpbanner-add', element: <MpBannerAdd />, breadcrumbName: 'MP Banner Add' },
            ],
          },
        ],
      },
      {
        path: 'partner',
        breadcrumbName: 'Partner',
        children: [
          { index: true, element: <PartnerType /> },
          { path: 'partner-type', element: <PartnerType />, breadcrumbName: 'Partner Type' },
          {
            path: 'partner-list',
            breadcrumbName: 'Partner List',
            children: [
              { index: true, element: <PartnerList /> },
              { path: 'partner-detail/:id', element: <PartnerDetail />, breadcrumbName: 'Partner Detail' },
            ],
          },
        ],
      },
    ],
    key: 'store',
  },
  {
    path: '/reportEmbed',
    breadcrumbName: 'reportEmbed',
    element: <ReportEmbed />,
    key: 'report_embed',
  },
  { path: '/authloading', element: <AuthLoading />, key: 'store' },
  { path: '/login', element: <Login />, key: 'store' },
  { path: '/store/select', element: <SelectStore />, key: 'store' },
  { path: '/login/store', element: <LoginStore />, key: 'store' },
  { path: '/login/brand', element: <LoginBrand />, key: 'store' },
  { path: '/resetPassword', element: <ResetPassword />, key: 'store' },
  { path: '/register', element: <Register />, key: 'store' },
  { path: '/uploadReport', element: <UploadReport />, key: 'store' },
  { path: '*', element: <Home />, key: 'store' },
  { path: '/pos/checkout', element: <PosCheckoutIndex />, key: 'store' },
]

// The useRoutes() hook allows you to define your routes as JavaScript objects
// instead of <Routes> and <Route> elements. This is really just a style
// preference for those who prefer to not use JSX for their routes config.

export default routes
