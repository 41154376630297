import apis from '@/framework/config/api-config'
import ApiRoot from '@/framework/api/fetcher'
import { getDataFormSeamCookie } from '@/utils/utils'

export const getSubscriptionList = async (param: any) => {
  const res = await ApiRoot({ url: apis.fulfillment }).subscriptions().getSubscriptionListPage({
    body: param
  })
  return {
    records: res?.subscriptionFindPage?.records ?? [],
    total: res?.subscriptionFindPage?.total ?? 0
  }
}

export const pauseSubscription = async (id: string) => {
  const res = await ApiRoot({ url: apis.fulfillment }).subscriptions().pauseSubscription(id)
  return res?.subscriptionPause || false
}

export const resumeSubscription = async (id: string) => {
  const res = await ApiRoot({ url: apis.fulfillment }).subscriptions().resumeSubscription(id)
  return res?.subscriptionResume || false
}

export const subscriptionUpdate = async (input: any) => {
  const params = {
    ...input,
    storeId: getDataFormSeamCookie('rc_sc_login_store_id')
  }
  const res = await ApiRoot({ url: apis.fulfillment }).subscriptions().subscriptionUpdate(params)
  return res?.subscriptionUpdate || false
}

export const getSubscriptionDetail = async (id: string) => {
  const res = await ApiRoot({ url: apis.fulfillment }).subscriptions().subscriptionDetail({
    id
  })
  return res || {}
}
// export const getSubscriptionFindByConsumerId = async (consumerId: any) => {
//   const res = await ApiRoot().subscriptions().subscriptionFindByConsumerId({
//     consumerId
//   })
//   console.log("get subscriptiondetail view data:", res)
//   return res?.subscriptionFindByConsumerId || []
// }

export const updateSubscriptionAddress = async (id: string, address: any) => {
  const res = await ApiRoot({ url: apis.fulfillment }).subscriptions().updateSubscriptionAddress(id, address)
  return res?.subscriptionUpdateAddress || false
}

export const updateNextDeliveryDate = async (id: string, date: string) => {
  const res = await ApiRoot({ url: apis.com_subscription }).subscriptions().subscriptionScheduleNextDelivery({ body: { id, nextDeliveryDate: date, operator: 'zz' } })
  return res?.subscriptionScheduleNextDelivery || false
}

export const upsertSubscriptionComment = async (param: any) => {
  const res = await ApiRoot({ url: apis.fulfillment }).subscriptions().upsertSubscriptionComment({ body: param })
  return res?.subscriptionCommentUpsert || false
}

export const subscriptionSettingCreate = async (param: any) => {
  const res = await ApiRoot({ url: apis.fulfillment }).subscriptions().subscriptionSettingCreate(param)
  return res?.subscriptionSettingCreate || false
}

export const subscriptionSettingFindPage = async (param: any) => {
  const res = await ApiRoot({ url: apis.fulfillment }).subscriptions().subscriptionSettingFindPage(param)
  return res?.subscriptionSettingFindPage || []
}

export const subscriptionSettingUpdate = async (param: any) => {
  const res = await ApiRoot({ url: apis.fulfillment }).subscriptions().subscriptionSettingUpdate(param)
  return res?.subscriptionSettingUpdate || false
}

export const subscriptionSettingDelete = async (param: any) => {
  const res = await ApiRoot({ url: apis.fulfillment }).subscriptions().subscriptionSettingDelete(param)
  return res?.subscriptionSettingDelete || false
}
