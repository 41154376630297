/*
 * @Author: yuszhou
 * @Date: 2023-02-07 21:22:14
 * @LastEditTime: 2023-03-24 00:41:06
 * @LastEditors: yuszhou
 * @Description:
 * @FilePath: \rc_china_storeportal\src\contexts\TabContext.js
 */
import React, { createContext, useState, useEffect } from 'react'
import { getDataFormSeamCookie, setToSemeCookie } from '@/utils/utils'
import { modules } from '@/common/components/Header'
export const TabContext = createContext()

const TabContextProvider = (props) => {
  const [activeTab, setActiveTab] = useState(0)

  const sendActiveTab = (index) => {
    setActiveTab(index)
    setToSemeCookie('activeTab', index)
  }

  useEffect(() => {
    if (process.env.NODE_ENV == 'production') {
      const moudulesDatas = modules.find((res) => res.url.indexOf(window.location.host) > -1)
      setActiveTab(Number(moudulesDatas.value) ?? 0)
    } else {
      const tab = getDataFormSeamCookie('activeTab') || 0
      setActiveTab(Number(tab))
    }
  }, [])

  return <TabContext.Provider value={{ activeTab, sendActiveTab }}>{props.children}</TabContext.Provider>
}

export default TabContextProvider
